import { useEffect, useState } from 'react';
import type { NextPage } from 'next';
import {
  Title,
  Box,
  Group,
  Overlay,
  Text,
  Flex,
  Card,
  Center,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery, useScrollIntoView, useSessionStorage } from '@mantine/hooks';
import Snowfall from 'react-snowfall';
import { Category } from 'types';
import {
  HomepageQuote,
  HomepageCards,
  HomepageCarousel,
  HomepagePartnerWithUs,
  HomepageStartBooking,
  CompanyRatingsBar,
  CompanyRatingsCarousel,
} from 'components';
import dayjs from 'dayjs';
import { NextSeo } from 'next-seo';
import HomeSearchFilterDrawer from 'components/MainSearchFilterDrawer/MainSearchFilterDrawer';
import { useSavedSearchValues } from 'context/SavedSearchFilterValuesContext';
import InactiveHomepageSearchBarDesktop from 'components/InactiveHomepageSearchBarDesktop/InactiveHomepageSearchBarDesktop';
import ActiveMainSearchBarDesktop from 'components/ActiveMainSearchBarDesktop/ActiveMainSearchBarDesktop';
import { Pebbles } from '@ui';
import { trackAction, Actions } from 'utils/amplitude';
import classes from './styles.module.scss';
import classNames from 'classnames';
import { MagnifyingGlass } from '@phosphor-icons/react';

interface IndexRouteProps {
  categories: Category[];
}

const Home: NextPage<IndexRouteProps> = () => {
  const theme = useMantineTheme();
  const { activeSearchBarDropDown, activeDesktopFilter, locationString, toggle, fetching } =
    useSavedSearchValues();

  const isMobile = useMediaQuery('(max-width: 768px)', true);
  const [showSnow, setShowSnow] = useState<boolean>(false);

  useEffect(() => {
    setShowSnow(true);
  }, []);

  const { scrollIntoView, targetRef } = useScrollIntoView<HTMLDivElement>({ offset: 10 });
  const { scrollIntoView: scrollToReviews, targetRef: reviewsRef } =
    useScrollIntoView<HTMLDivElement>({ duration: 750, offset: 96 });

  const [savedScrollPosition, setSavedScrollPosition] = useSessionStorage({
    key: 'saved_scroll_position',
  });

  // Reset saved scroll position
  useEffect(() => {
    if (savedScrollPosition) {
      setSavedScrollPosition('0');
    }
  }, [savedScrollPosition, setSavedScrollPosition]);

  // TODO might have to move fetching to context following utm changes
  if (fetching) {
    return (
      <Center style={{ height: '50vh' }}>
        <Pebbles />
      </Center>
    );
  }

  return (
    <>
      <NextSeo
        title="Pebble Activities"
        description="Discover and instantly book
        fun and entertainment for
        your child."
        openGraph={{
          title: 'Pebble Activities',
          description: 'Discover and instantly book fun and entertainment for your child.',
          site_name: 'Pebble Activities',
          images: [
            {
              width: 400,
              height: 225,
              url: '/provisional_img.png',
              alt: 'Laptop and phone with Pebble app',
            },
          ],
        }}
      />
      <div
        className={classNames(classes.headerSearchSection, {
          [classes.activeSearchMobile]: isMobile,
        })}
        ref={targetRef}
      >
        {showSnow && <Snowfall snowflakeCount={isMobile ? 50 : 150} />}
        <Box className={classes.searchWrapper}>
          <Box className={classes.titleWrapper}>
            <Title order={2} className={classes.title}>
              A world of <span className={classes.emphasizedTitle}>adventure</span> awaits!
            </Title>
            <Title order={4} className={classes.subTitle}>
              Instantly book from 1000s of activities for your children from trusted providers
            </Title>
          </Box>
          {isMobile ? (
            <Flex justify={'center'} py="xl">
              <Card
                className={classes.card}
                p={'0.5rem'}
                onClick={() => {
                  toggle();
                  trackAction(Actions.SEARCH_BAR_SELECT);
                }}
              >
                <Group wrap="nowrap">
                  <MagnifyingGlass
                    className={classes.mobileSearchIcon}
                    size={18}
                    color={theme.colors.blue[8]}
                    weight="bold"
                  />
                  <Text py={4} className={classes.filterHeader} truncate="end">
                    {locationString ? `Near ${locationString}` : 'Find an activity'}
                  </Text>
                </Group>
              </Card>
            </Flex>
          ) : activeDesktopFilter && !isMobile ? (
            <Flex justify={'center'}>
              <ActiveMainSearchBarDesktop />
            </Flex>
          ) : (
            <Flex justify={'center'}>
              <InactiveHomepageSearchBarDesktop />
            </Flex>
          )}
          <CompanyRatingsBar onClick={() => scrollToReviews()} />
        </Box>
        <HomeSearchFilterDrawer />
        {activeSearchBarDropDown && activeDesktopFilter && (
          <Overlay opacity={0.7} color="#000" zIndex={2} h={'200%'} />
        )}
      </div>

      <>
        <HomepageCarousel />
        <div className={classes.backgroundWrapper}>
          <Group mb={isMobile ? 'xs' : 'xl'}>
            <HomepageCards />
          </Group>
          <Group mb={isMobile ? 'xs' : 'xl'}>
            <HomepageQuote />
          </Group>
          <Box
            mb={isMobile ? 'xs' : 'xl'}
            mx="lg"
            ref={reviewsRef}
            className={classes.carouselWrapper}
          >
            <CompanyRatingsCarousel />
          </Box>

          <HomepageStartBooking onClick={() => scrollIntoView({ alignment: 'center' })} />
        </div>
        <HomepagePartnerWithUs />
        <footer className={classes.footer}>
          <div className={classes.footerLinks}>
            <a href="https://bookpebble.co.uk/terms" target="_blank">
              Terms & Conditions
            </a>
            <div className={classes.divider}></div>
            <a href="https://bookpebble.co.uk/privacy-policy" target="_blank">
              Privacy Policy
            </a>
            <div className={classes.divider}></div>
            <a href="https://bookpebble.co.uk/support" target="_blank">
              Contact us
            </a>
          </div>
          <p className={classes.footerCopyright}>Copyright © {dayjs().year()} Pebble</p>
        </footer>
      </>
    </>
  );
};

export default Home;
